<template>
    <div>
        <div class="ma-2">
            <v-btn
                    color="green"
                    @click="toggleModify()"
                    class="mx-1"
            >
                Create
            </v-btn>
            <v-btn
                    color="blue"
                    @click="doFetch()"
                    class="mx-1"
            >
                Refresh
            </v-btn>
        </div>
        <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page="15"
                class="elevation-1"
        >
            <template v-slot:[`item.planLevel`]="{ item }">
                Level <span class="blue--text">{{item['planLevel']}}</span>
                <v-btn
                        dark
                        small
                        color="red"
                        icon
                        class="mx-2"
                        @click="doDeletePlan(item)"
                >
                    <v-icon dark>
                        mdi-delete
                    </v-icon>
                </v-btn>
                <v-btn
                        dark
                        small
                        color="blue"
                        icon
                        class="mx-2"
                        @click="toggleModify(item)"
                >
                    <v-icon dark>
                        mdi-pencil
                    </v-icon>
                </v-btn>
            </template>
            <template v-slot:[`item.planPackages`]="{ item }">
                <v-list style="max-height: 220px; overflow-y: auto;">
                    <v-list-item
                        v-for="(item,index) in item['planPackages']"
                        :key="index"
                    >
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item }} DAY(s)
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </v-data-table>
        <v-dialog
                v-model="boxModify"
                max-width="900px"
                persistent
        >
            <v-card>
                <v-card-title>
                    <template v-if="item['planId']">
                        <span class="headline">Modify Plan: LEVEL {{item['planLevel']}}</span>
                    </template>
                    <template v-else>
                        <span class="headline">Create Plan</span>
                    </template>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="item['planLevel']"
                                        label="Level"
                                        required
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                        v-model="item['planDescription']"
                                        label="Description"
                                        required
                                />
                            </v-col>
                            <v-col cols="3">
                                <div class="mb-1">
                                    <v-text-field
                                            v-model="day"
                                            label="Day"
                                            required
                                            class="mx-1"
                                    />
                                    <v-btn dark color="green" icon :disabled="isPackageExists" @click="doAddPackage">
                                        <v-icon dark>
                                            mdi-plus
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col cols="9">
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th class="text-left">
                                                Days
                                            </th>
                                            <th class="text-left">
                                                Remove
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr
                                         v-for="(item,index) in item['planPackages']"
                                         :key="index"
                                        >
                                            <td>
                                                <span class="mx-1">{{ item }} DAY(s)</span>
                                            </td>
                                            <td>
                                                <v-btn
                                                 dark
                                                 small
                                                 color="red"
                                                 icon
                                                 @click="doRemovePackage(index)"
                                                >
                                                    <v-icon>
                                                        mdi-delete
                                                    </v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>

                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                            color="green"
                            dark
                            @click="doModify"
                            :disabled="checkModify || Overlay"
                            :loading="Overlay"
                    >
                        {{ item['planId'] ? 'Modify' : 'Create' }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                            color="blue"
                            dark
                            @click="boxModify = false"
                    >
                        Close
                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay :value="Overlay">
            <v-progress-circular
                    indeterminate
                    size="64"
            />
        </v-overlay>
    </div>
</template>

<script>
import { fetchData } from '../helpers';
import Notice from '../helpers/notice';
export default {
    name: "Plans",
    data(){
        return {
            items: [],
            headers: [
                { text: 'Level', align: 'center', sortable: true,value: 'planLevel',width:"auto" },
                { text: 'Description', align: 'center', sortable: false,value: 'planDescription',width:"auto"},
                { text: 'Packages', align: 'center', sortable: true,value: 'planPackages',width:"auto"},
            ],
            item: {
                planPackages: [],
            },
            boxModify: false,
            Overlay: false,
            day: 1,
        }
    },
    computed:{
        isPackageExists(){
            return this.item['planPackages'].includes(this.day);
        },
        checkModify(){
            return !this.item['planLevel'] || !this.item['planDescription'] || !this.item['planPackages'].length;
        }
    },
    methods:{
        toggleModify(item=null){
            if(item){
                this.item = JSON.parse(JSON.stringify(item));
            }else{
                this.item = {
                    planPackages: [],
                };
            }
            this.boxModify = true;
        },
        doModify(){
            this.Overlay = true;
            const method = this.item['planId'] ? 'update' : 'add';
            fetchData(`${this.$API}/api/Plans/${method}`,this.item).then((json)=>{
                const {success, msg} = json;
                if(success) {
                    Notice.success({message: msg});
                    this.boxModify = false;
                    this.doFetch();
                }else{
                    Notice.error({message: msg ? msg : 'SERVER ERROR !!!'})
                }
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    Notice.error({message: 'SERVER ERROR !!!'})
                }
            }).finally(()=>{
                this.Overlay = false;
            });
        },
        async doDeletePlan(item){
            const confirm = await this.$confirm(`Do you really want to do this action ? This data can't restore !`,{ title: 'Warning' });
            if(confirm){
                this.Overlay = true;
                fetchData(`${this.$API}/api/Plans/delete`,{
                    planId: item['planId']
                }).then((json)=>{
                    const {success, msg} = json;
                    if(success) {
                        Notice.success({message: msg});
                        this.doFetch();
                    }else{
                        Notice.error({message: msg ? msg : 'SERVER ERROR !!!'})
                    }
                },(err)=>{
                    if(err === 401){
                        this.logout();
                    }else{
                        Notice.error({message: 'SERVER ERROR !!!'})
                    }
                }).finally(()=>{
                    this.Overlay = false;
                });
            }
        },
        doRemovePackage(index){
            this.item['planPackages'].splice(index,1);
        },
        doAddPackage(){
            if(this.isPackageExists) return;
            this.item['planPackages'].push(this.day);
        },
        doFetch(){
            this.Overlay = true;
            fetchData(`${this.$API}/api/Plans/all`).then((json)=>{
                const {success, data} = json;
                if(success && data){
                    this.items = json.data;
                }else{
                    Notice.error({message:'SERVER ERROR !!!'});
                }
            },(err)=>{
                if(err === 401){
                    this.logout();
                }else{
                    Notice.error({message:'SERVER ERROR !!!'});
                }
            }).finally(()=>{
                this.Overlay = false;
            });
        }
    },
				mounted() {
        this.doFetch();
    }
}
</script>

<style scoped>

</style>